import React from "react";
import styled from "styled-components";
import dateFormat from "dateformat";

const Template = (props) => {
  return (
    <Container>
      <Events data={props.data} image={props.image} type={props.type} />
    </Container>
  );
};

export default Template;

const Events = ({ data, image, type }) => {
  const now = new Date();
  return (
    <Grid>
      {data.map((item, key) => (
        <NewsContainer key={key}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <img src={image} width="100px" height="100px" />
            <NewsCard key={key}>
              <NewsTitle>{item.title.substring(0, 30)}</NewsTitle>
              {type === "EVENT" || type === "NEWS" ? (
                <p style={{ color: "#84ba40" }}>
                  {dateFormat(item.eventDate || now, "mmmm dS, yyyy")}
                </p>
              ) : null}
              {type === "NOTIFICATIONS" ? (
                <p style={{ color: "#84ba40" }}>
                  {dateFormat(
                    item.updatedAt || now,
                    "mmmm dS, yyyy, h:MM:ss TT"
                  )}
                </p>
              ) : null}
              <NewsDate>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description.substring(0, 100),
                  }}
                />
              </NewsDate>
              <a
                href={`/detail/?id=${item.id}&type=${type}`}
                style={{ textDecoration: "none" }}>
                <Button>Read More</Button>
              </a>
            </NewsCard>
          </div>
        </NewsContainer>
      ))}
    </Grid>
  );
};

const Button = styled.button`
  position: absolute;
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  bottom: 0;
  display: flex;
  justifycontent: center;
  alignitems: center;
`;

const NewsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
const NewsDate = styled.time``;

const NewsCard = styled.div`
  min-height: 200px;
  position: relative;
  border-bottom: 1px solid #fff;
  padding: 5px 20px;
  &:last-child {
    border-bottom: 0;
  }
`;

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 14px 20px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
