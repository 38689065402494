import React, { useState, useEffect } from "react";
import styled from "styled-components";
import notificationIcon from "../images/notificationIcon.png";
import Http from "../helpers/Http";
import Template from "./Template";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/5")
        .then((response) => {
          setNotifications(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Template
        data={notifications}
        image={notificationIcon}
        type="NOTIFICATIONS"
      />
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;
